import { Config } from '../translate.types';

export const LANG_EN_CONFIG: Config = {
  locale: 'en-US',
  localeDate: 'en',
  code: 'USD',
  date: {
    format: 'MM/DD/YYYY',
    mask: '99/99/9999',
    timezone: {
      format: 'YYYY-MM-DD',
      value: 'T03:00:00.000Z',
    },
  },
  currency: {
    thousands: ',',
    decimal: '.',
    symbol: 'USD$',
  },
  number: {
    thousands: ',',
    decimal: '.',
  },
};
