import { Injectable } from '@angular/core';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

import { TranslateService } from '../translate/translate.service';
import { MASK_TYPE } from './mask.enum';

@Injectable({ providedIn: 'root' })
export class MaskService {
  private readonly CEP: any = '00000-000';
  private readonly IE: any = '000.000.000.000.00';
  private readonly CNPJ: any = '00.000.000/0000-00';
  private readonly CPF: any = '000.000.000-00';
  private readonly TELEFONE: any = '(00) 0000-0000';
  private readonly CELULAR: any = '(00) 00000-0000';
  private readonly PLACA: any = 'SSS-0S00';
  private readonly HORA: any = '00:00:00';

  constructor(private readonly translateService: TranslateService) {}

  public setMask(mask: MASK_TYPE): any {
    switch (mask) {
      case MASK_TYPE.CEP:
        return this.CEP;

      case MASK_TYPE.IE:
        return this.IE;

      case MASK_TYPE.CNPJ:
        return this.CNPJ;

      case MASK_TYPE.CPF:
        return this.CPF;

      case MASK_TYPE.TELEFONE:
        return this.TELEFONE;

      case MASK_TYPE.CELULAR:
        return this.CELULAR;

      case MASK_TYPE.PLACA:
        return this.PLACA;

      case MASK_TYPE.DATA:
        return this.translateService.loadConfig.date.mask;

      case MASK_TYPE.HORA:
        return this.HORA;

      case MASK_TYPE.DINHEIRO:
        const maskDinheiro: NgxCurrencyConfig = {
          align: 'rigth',
          allowNegative: true,
          allowZero: true,
          precision: 2,
          prefix: '',
          suffix: '',
          nullable: false,
          inputMode: NgxCurrencyInputMode.Financial,
          ...this.translateService.loadConfig.currency,
        };
        return maskDinheiro;

      case MASK_TYPE.NUMERO:
        const maskNumero: NgxCurrencyConfig = {
          align: 'right',
          allowNegative: true,
          allowZero: true,
          thousands: '',
          decimal: '.',
          precision: 0,
          prefix: '',
          suffix: '',
          nullable: false,
          inputMode: NgxCurrencyInputMode.Natural,
        };
        return maskNumero;
    }
  }
}
