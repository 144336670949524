import { Injector } from '@angular/core';
import { AlertService } from './alert';
import { MaskService } from './mask';
import { SpinService } from './spin';
import { ToastService } from './toast';
import { TranslateService } from './translate';

export abstract class BaseService {
  protected _alert: AlertService;
  protected _mask: MaskService;
  protected _spin: SpinService;
  protected _toast: ToastService;
  protected _translate: TranslateService;

  constructor(injector: Injector) {
    this._alert = injector.get(AlertService);
    this._mask = injector.get(MaskService);
    this._spin = injector.get(SpinService);
    this._toast = injector.get(ToastService);
    this._translate = injector.get(TranslateService);
  }
}
