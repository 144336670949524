/**
 * Retorna True se valor for null ou undefined
 * @param  {any} value
 * @returns boolean
 */
export function isNull(value: any): boolean {
  return value === undefined || value === null;
}

/**
 * Retorna True se valor for diferente de null ou undefined
 * @param  {any} value
 * @returns boolean
 */
export function isPresent(value: any): boolean {
  return value !== undefined && value !== null;
}
