import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '../../translate';
import { TYPE } from '../toast.enum';

export const CONFIG: Partial<IndividualConfig> = {
  disableTimeOut: false,
  timeOut: 2000,
  progressBar: true,
  closeButton: true,
  extendedTimeOut: 1000,
  enableHtml: true,
  toastClass: 'ngx-toastr',
  titleClass: 'toast-title',
  messageClass: 'toast-message',
  positionClass: 'toast-top-right',
  tapToDismiss: true,
};

@Injectable({ providedIn: 'root' })
export class DesktopToastService {
  constructor(private readonly toastrService: ToastrService, private readonly translateService: TranslateService) {}

  public message({ type, message, title, fixed }: { type: TYPE; message: any; title: string; fixed: boolean }): void {
    switch (type) {
      case TYPE.SUCCESS:
        title = title ?? this.translateService.translate('SUCESSO');
        setTimeout(() => {
          this.toastrService.success(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      case TYPE.WARN:
        title = title ?? this.translateService.translate('ATENCAO');
        setTimeout(() => {
          this.toastrService.warning(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      case TYPE.ERROR:
        title = title ?? this.translateService.translate('ERRO');
        setTimeout(() => {
          this.toastrService.error(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      case TYPE.INFO:
        title = title ?? this.translateService.translate('INFORMACAO');
        setTimeout(() => {
          this.toastrService.info(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
        break;
      default:
        title = title ?? this.translateService.translate('SUCESSO');
        setTimeout(() => {
          this.toastrService.success(message, title, { ...CONFIG, disableTimeOut: fixed });
        }, 0);
    }
  }
}
