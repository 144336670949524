import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventsInterface } from './events.interface';
import { EVENTS_TYPE } from './events.types';

@Injectable({ providedIn: 'root' })
export class EventsService {
  private readonly event: Subject<EventsInterface<any>> = new Subject<EventsInterface<any>>();

  public get events(): Observable<EventsInterface<any>> {
    return this.event.asObservable();
  }

  public broadcast<T = any>(action: EVENTS_TYPE, payload: T = null): void {
    this.event.next({
      action,
      payload,
    });
  }
}
