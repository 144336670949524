import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { StorageInterface } from '../storage.interface';

@Injectable({ providedIn: 'root' })
export class SessionService implements StorageInterface {
  constructor(private readonly storage: SessionStorageService) {}

  public set(key: string, value: any): void {
    this.storage.store(key, value);
  }

  public get(key: string): any {
    return this.storage.retrieve(key);
  }

  public remove(key: string): void {
    this.storage.clear(key);
  }
}
