import { InjectionToken } from '@angular/core';
import { LANG_EN_DICTIONARY } from './dictionary/en';
import { LANG_ES_DICTIONARY } from './dictionary/es';
import { LANG_PT_DICTIONARY } from './dictionary/pt';

const dictionary = {
  en: LANG_EN_DICTIONARY,
  es: LANG_ES_DICTIONARY,
  pt: LANG_PT_DICTIONARY,
};

export const DICTIONARY: InjectionToken<string> = new InjectionToken<string>('DICTIONARY');

export const TRANSLATION_DICTIONARY = [
  {
    provide: DICTIONARY,
    useValue: dictionary,
  },
];
