import { InjectionToken } from '@angular/core';
import { LANG_EN_CONFIG } from './config/en';
import { LANG_ES_CONFIG } from './config/es';
import { LANG_PT_CONFIG } from './config/pt';

const config = {
  en: LANG_EN_CONFIG,
  es: LANG_ES_CONFIG,
  pt: LANG_PT_CONFIG,
};

export const CONFIG: InjectionToken<string> = new InjectionToken<string>('CONFIG');

export const TRANSLATION_CONFIG = [
  {
    provide: CONFIG,
    useValue: config,
  },
];
