import { registerLocaleData } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/pt-br';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale, ptBrLocale } from 'ngx-bootstrap/locale';
import { STORAGE_TYPE, StorageService } from '../storage';
import { CONFIG } from './translate.config';
import { DICTIONARY } from './translate.dictionary';
import { Config, Languages } from './translate.types';

defineLocale('es', esLocale);
defineLocale('pt-br', ptBrLocale);

@Injectable({ providedIn: 'root' })
export class TranslateService implements OnDestroy {
  private currentLanguage: Languages;

  public onLanguageChanged$: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    @Inject(CONFIG) private readonly config: string,
    @Inject(DICTIONARY) private readonly dictionary: string,
    private readonly bsLocaleService: BsLocaleService,
    private readonly bsDatepickerConfig: BsDatepickerConfig,
    private readonly bsDaterangePickerConfig: BsDaterangepickerConfig,
    private readonly storageService: StorageService
  ) {
    this.loadLanguage();
    this.setLocaleDate();
    this.saveLanguage();
    this.onLanguageChanged$.emit(this.currentLanguage);
  }

  public ngOnDestroy(): void {
    this.onLanguageChanged$.unsubscribe();
  }

  public useLanguage = (language: Languages): void => {
    this.currentLanguage = language;
    this.setLocaleDate();
    this.saveLanguage();
    this.onLanguageChanged$.emit(language);
  };

  public translate = (key: string): string => {
    return this.dictionary[this.currentLanguage][key] ?? key;
  };

  public get loadConfig(): Config {
    return this.config[this.currentLanguage];
  }

  private readonly setLocaleDate = (): void => {
    registerLocaleData(this.loadConfig.localeDate);
    this.bsLocaleService.use(this.loadConfig.localeDate);
    this.bsDatepickerConfig.dateInputFormat = this.loadConfig.date.format;
    this.bsDatepickerConfig.showWeekNumbers = false;
    this.bsDatepickerConfig.containerClass = 'theme-blue';
    this.bsDatepickerConfig.isAnimated = true;
    this.bsDaterangePickerConfig.dateInputFormat = this.loadConfig.date.format;
    this.bsDaterangePickerConfig.showWeekNumbers = false;
    this.bsDaterangePickerConfig.containerClass = 'theme-blue';
    this.bsDaterangePickerConfig.isAnimated = true;
    dayjs.locale(this.currentLanguage);
  };

  private readonly loadLanguage = (): Languages => {
    const language: Languages = this.storageService.get(STORAGE_TYPE.LOCAL, 'language') ?? 'pt'; // window.navigator.language
    this.currentLanguage = language;
    return language;
  };

  private readonly saveLanguage = (): void => {
    this.storageService.set(STORAGE_TYPE.LOCAL, 'language', this.currentLanguage);
  };

  public dateIsValid(value: string): boolean {
    return dayjs(value, this.loadConfig.date.format, true).isValid();
  }

  public stringToDate(value: string): Date {
    const output = new Date(dayjs(value, this.loadConfig.date.format, true).toDate()).toISOString();
    return new Date(output);
  }

  public dateToString(value: Date): string {
    const output: string = dayjs(value).format(this.loadConfig.date.format);
    return output;
  }
}
