export enum MASK_TYPE {
  CEP,
  IE,
  CNPJ,
  CPF,
  TELEFONE,
  CELULAR,
  PLACA,
  DATA,
  HORA,
  NUMERO,
  DINHEIRO,
}
