import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@NgModule({})
export class ToastModule {
  public static forRoot(): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [ToastrService],
    };
  }
}
