export const LANG_PT_DICTIONARY = {
  LANG_EN: 'Inglês',
  LANG_ES: 'Espanhol',
  LANG_PT: 'Português',

  ABERTURA: 'Abertura',
  ADMIN: 'Administrador',
  ALTERAR: 'Alterar',
  ANTERIOR: 'Anterior',
  ATENCAO: 'Atenção',
  BAIRRO: 'Bairro',
  BANCO: 'Banco',
  BANCOS: 'Bancos',
  CADASTRAR: 'Cadastrar',
  CADASTRO: 'Cadastro',
  CAMPO: 'Verifique as informações',
  CANCELAR: 'Cancelar',
  CELULAR: 'Celular',
  CEP: 'CEP',
  CHAVE: 'Chave',
  CHAVES_PAGAMENTO: 'Chaves de Pagamento',
  CHAVES_RETIRADA: 'Chaves de Retirada',
  CHAVES: 'Chaves',
  CIDADE: 'Cidade',
  CNPJ: 'CNPJ',
  COMISSAO: 'Comissão',
  COMISSOES: 'Comissões',
  COMPLEMENTO: 'Complemento',
  CONFIGURACOES: 'Configurações',
  CONFIGURAR: 'Configurar',
  CONFIRMAR: 'Confirmar',
  CONTA: 'Conta',
  CONTAS: 'Contas',
  CPF: 'CPF',
  CRIAR: 'Criar',
  DASHBOARD: 'Dashboard',
  DATA: 'Data',
  DEVOLUCAO: 'Devolução',
  DEVOLUCOES: 'Devoluções',
  EMAIL: 'E-mail',
  EMPRESA: 'Empresa',
  ENDERECO: 'Endereço',
  ENVIAR: 'Enviar',
  ERRO: 'Erro',
  EXCLUIR: 'Excluir',
  EXTRATO: 'Extrato',
  FANTASIA: 'Nome Fantasia',
  FECHAR_PAGAMENTO: 'Fechar Pagamento',
  FECHAR: 'Fechar',
  FINANCEIRO: 'Financeiro',
  GRAVAR: 'Gravar',
  HISTORICO: 'Histórico',
  HORA: 'Hora',
  IMPRIMIR: 'Imprimir',
  INFORMACAO: 'Informação',
  LANCAMENTO: 'Lançamento',
  LANCAMENTOS: 'Lançamentos',
  LOGRADOURO: 'Logradouro',
  NAO: 'Não',
  NASCIMENTO: 'Nascimento',
  NOME: 'Nome',
  NUMERO: 'Número',
  OK: 'OK',
  OPERACOES: 'Operações',
  PAGAMENTO: 'Pagamento',
  PAGAMENTOS: 'Pagamentos',
  PEDIDO: 'Pedido',
  PERIODO: 'Período',
  PRIMEIRO: 'Primeiro',
  PROXIMO: 'Próximo',
  RAZAO: 'Razão Social',
  RESPONSAVEL: 'Responsável',
  RETIRADA: 'Retirada',
  RETIRADAS: 'Retiradas',
  SAIR: 'Sair',
  SALDO: 'Saldo',
  SENHA: 'Senha',
  SIM: 'Sim',
  STATUS: 'Status',
  SUCESSO: 'Sucesso',
  SUPORTE: 'Suporte',
  TAXA: 'Taxa',
  TAXAS: 'Taxas',
  TIPO: 'Tipo',
  TELEFONE: 'Telefone',
  TRANSACAO: 'Transação',
  TRANSACOES: 'Transações',
  UF: 'UF',
  ULTIMO: 'Último',
  USUARIO: 'Usuário',
  USUARIOS: 'Usuários',
  VALOR: 'Valor',

  PLACEHOLDER_CODIGO: 'Digite o Código',
  PLACEHOLDER_NOME: 'Digite o Nome',
  PLACEHOLDER_EMAIL: 'Digite o E-mail',
  PLACEHOLDER_SENHA: 'Digite a Senha',
  PLACEHOLDER_CEP: '00000-000',
  PLACEHOLDER_IE: '000.000.000.000.00',
  PLACEHOLDER_CNPJ: '00.000.000/0000-00',
  PLACEHOLDER_CPF: '000.000.000-00',
  PLACEHOLDER_CONTATO: 'Digite o Contato',
  PLACEHOLDER_TELEFONE: '(00) 0000-0000',
  PLACEHOLDER_CELULAR: '(00) 00000-0000',
  PLACEHOLDER_RAZAO: 'Digite a razão social',
  PLACEHOLDER_FANTASIA: 'Digite o nome fantasia',
  PLACEHOLDER_FILIACAO: 'Digite a filiação',
  PLACEHOLDER_BANCO: 'Digite o nome do banco',
  PLACEHOLDER_CHAVE: 'Digite a chave PIX',

  TOOLTIP_CADASTRAR: 'Cadastrar',
  TOOLTIP_ALTERAR: 'Alterar',
  TOOLTIP_EXCLUIR: 'Excluir',
  TOOLTIP_CONTA: 'Conta',
  TOOLTIP_AJUDA: 'Ajuda',
  TOOLTIP_SUPORTE: 'Suporte',
  TOOLTIP_CONTATO: 'Contato',
  TOOLTIP_ALERTA: 'Alertas',
  TOOLTIP_USUARIO: 'Usuário',
  TOOLTIP_SELECIONAR: 'Selecionar',
  TOOLTIP_OPCOES: 'Opções',
  TOOLTIP_CONFIGURACOES: 'Configurações',
  TOOLTIP_PERIODO: 'Selecionar Período',
  TOOLTIP_PESQUISA: 'Selecionar Campo',
  TOOLTIP_FILTRO: 'Filtrar Informações',
  TOOLTIP_EXCLUIR_CHAVE: 'Excluir Chave',

  VALIDACAO_OBRIGATORIO: 'Campo obrigatório',
  VALIDACAO_TAMANHO: 'Tamanho do Campo inválido',
  VALIDACAO_EMAIL: 'E-mail inválido',
  VALIDACAO_EMAIL_EXISTE: 'E-mail já cadastrado',
  VALIDACAO_SENHA: 'Senha muito curta',
  VALIDACAO_SENHA_CURTA: 'Senha muito curta',
  VALIDACAO_SENHA_DIFERENTES: 'Senhas não conferem',
  VALIDACAO_CEP: 'CEP inválido',
  VALIDACAO_CNPJ: 'CNPJ inválido',
  VALIDACAO_CNPJ_EXISTE: 'CNPJ já cadastrado',
  VALIDACAO_CPF: 'CPF inválido',
  VALIDACAO_CPF_EXISTE: 'CPF já cadastrado',
  VALIDACAO_LIMITE: 'Valor fora do limite',
  VALIDACAO_CHAVE: 'Chave inválida',

  USUARIO_LOGIN_SUCESSO: 'Login efetuado com sucesso',
  USUARIO_LOGIN_ERRO: 'Não foi possível efetuar o login',
  USUARIO_CRIAR_SUCESSO: 'Conta criada com sucesso',
  USUARIO_CRIAR_ERRO: 'Não foi possível criar a conta',
  USUARIO_SENHA_SUCESSO: 'Senha alterada com sucesso',
  USUARIO_SENHA_ERRO: 'Não foi possível alterar a senha, verifique e tente novamente',
  USUARIO_ESQUECER_SUCESSO: 'E-mail enviado com sucesso! Verifique sua caixa de entrada',
  USUARIO_ESQUECER_ERRO: 'Não foi possível enviar o e-mail, verifique os dados informados',
  USUARIO_CONFIRMAR_SUCESSO: 'Cadastro confirmado com sucesso',
  USUARIO_CONFIRMAR_ERRO: 'Não foi possível confirmar o cadastro',

  USUARIO_ESQUECER_HEADER: 'Esqueci Minha Senha',
  USUARIO_ESQUECER_TITLE: 'Enviaremos um link para redefinir sua senha no e-mail abaixo',
  USUARIO_ESQUECER_MENSAGEM:
    'Enviamos um e-mail para o endereço informado contendo mais instruções para redefinir sua senha.</br></br>Se você não receber o email dentro de alguns minutos, verifique a caixa de spam ou lixo eletrônico de seu email.</br></br>Se precisar de ajuda</br>contate nossa central de suporte.',
};
