import { Injectable } from '@angular/core';
import { STORAGE_TYPE } from './storage.enum';
import { storageMap } from './storage.map';

@Injectable({ providedIn: 'root' })
export class StorageFactory {
  public static new(storageType: STORAGE_TYPE): any {
    return storageMap.get(storageType);
  }
}
