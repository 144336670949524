import { ModuleWithProviders, NgModule } from '@angular/core';
import { TRANSLATION_CONFIG } from './translate.config';
import { TRANSLATION_DICTIONARY } from './translate.dictionary';

@NgModule({})
export class TranslateModule {
  public static forRoot(): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: TranslateModule,
      providers: [TRANSLATION_CONFIG, TRANSLATION_DICTIONARY],
    };
  }
}
