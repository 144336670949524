import validator from 'validator';

/**
 * Verifica se o Email é válido
 * @param  {string} value
 * @returns boolean
 */
export function validEmail(value: string): boolean {
  return validator.isEmail(value);
}
